.ant-select-dropdown {
    border: solid 1px #b4b4b4;
    box-shadow: none;
}

.ant-select-dropdown-menu-item {
    color: #1d1d1d;
}

.ant-select-dropdown-menu-item:hover,
.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled){
    background-color: #004fef;
    color: white;
    font-weight: bold;
}

.ant-select-dropdown-menu {
    margin-top: 0;
}

.ant-dropdown {
  min-width: 160px;
}

.ant-dropdown a {
  font-weight: 400;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: #4d4e5a;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  color: #004fef;
  background: transparent;
}

.ant-dropdown-menu {
  border: solid 1px #b4b4b4;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.09);
  padding: 16px;
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  margin: 4px -16px;
  background-color: #e3e3e3;
}

.ant-dropdown-placement-bottomRight .ant-dropdown-menu {
  margin-top: 10px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 5px 0;
}

.ant-dropdown-placement-bottomRight .ant-dropdown-menu:after,
.ant-dropdown-placement-bottomRight .ant-dropdown-menu:before {
  box-sizing: content-box;
  position: absolute;
  content: '';
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  border-top: none;
  left: auto;
  right: 10px;
  top: 0;
}

.ant-dropdown-placement-bottomRight .ant-dropdown-menu:after {
  margin-top: -8px;
  border-bottom-color: white;
}

.ant-dropdown-placement-bottomRight .ant-dropdown-menu:before {
  top: -9px;
  z-index: -1;
  border-bottom-color: #aeaeae;
}

@media (max-width: 576px) {
  .ant-dropdown-menu-item span:last-child {
    max-width: 230px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
